<template>
    <div class="wizard-address">
        <div class="wizard-header">
            <h1>Fill your address</h1>
            <p>We collect your address to verify your identity and ensure that the information you provide is accurate. We value your privacy and we assure you that your address will be kept confidential and will not be shared with any third parties.</p>
        </div>
        <div class="wizard-body">
            <div class="wizard-body__info">
                <h2>Provide your address details</h2>
                <p>Please fill in your address information for identification purposes.</p>
            </div>
            <form
                class="wizard-address__form"
                @submit.prevent="validate"
            >
                <div class="wizard-address__form-wrapper">
                    <div class="wizard-address__field wizard-address__form-wrapper--first-level">
                        <form-input
                            v-model="$v.address.$model"
                            outlined
                            legend="Address"
                            :field.sync="address"
                            :errors="validationMessage($v.address)"
                            :is-valid="$v.address.$dirty && !$v.address.$anyError"
                            @input.native="$v.address.$touch()"
                            @blur.native="$v.address.$touch()"
                        />
                    </div>
                    <div class="wizard-address__form-wrapper--second-level">
                        <div class="wizard-address__field">
                            <form-input
                                v-model="$v.city.$model"
                                legend="City"
                                :field.sync="city"
                                :errors="validationMessage($v.city)"
                                :is-valid="$v.city.$dirty && !$v.city.$anyError"
                                @input.native="$v.city.$touch()"
                                @blur.native="$v.city.$touch()"
                            />
                        </div>
                        <div class="wizard-address__field">
                            <form-select-countries
                                v-model="$v.country.$model"
                                :country="country"
                                label="Country"
                                tabindex="0"
                                :field.sync="country"
                                :errors="validationMessage($v.country)"
                                :is-valid="!$v.country.$anyError && !$v.country.$invalid"
                                @input.native="$v.country.$touch()"
                                @blur.native="$v.country.$touch()"
                            />
                        </div>
                    </div>
                    <div class="wizard-address__form-wrapper--third-level">
                        <div class="wizard-address__field">
                            <form-input
                                v-model="$v.zip.$model"
                                legend="ZIP"
                                :field.sync="zip"
                                :errors="validationMessage($v.zip)"
                                :is-valid="$v.zip.$dirty && !$v.zip.$anyError"
                                @input.native="$v.zip.$touch()"
                                @blur.native="$v.zip.$touch()"
                            />
                        </div>
                        <div
                            v-show="regionsLength > 0"
                            class="wizard-address__field wizard-address__field--region"
                        >
                            <form-select-region
                                v-model="$v.region.$model"
                                :region="region"
                                :country="country"
                                label="Region"
                                tabindex="0"
                                :field.sync="region"
                                :errors="validationMessage($v.region)"
                                :is-valid="!$v.region.$anyError && !$v.region.$invalid"
                                @input.native="$v.region.$touch()"
                                @blur.native="$v.region.$touch()"
                                @checkRequired="regionsLength = $event"
                                @click="regionsLength = $event"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="wizard-address__btn">
            <custom-button
                :type="'submit'"
                default
                @on-btn-click="submit"
            >
                Continue
            </custom-button>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/wizard/wizard-address/Rules'
import { formMessages } from '@/validation/wizard/wizard-address/Messages'

import {
    mapWriterFields
} from '@/store/modules/writer';

import { createNamespacedHelpers } from 'vuex';

import {
    ACTION_UPDATE_CONTACT_DETAILS
} from '@/store/modules/writer/action-types'
import { eventBus } from '@/helpers/event-bus'

const { mapActions: mapWriterActions } = createNamespacedHelpers('writer')

export default {
    name: 'Index',
    metaInfo: {
        title: 'Wizard Adress'
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            regionsLength: 0
        }
    },
    computed: {
        ...mapWriterFields([
            'writer_data',
            'writer_data.address',
            'writer_data.city',
            'writer_data.zip',
            'writer_data.country',
            'writer_data.region'
        ])
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapWriterActions([
            ACTION_UPDATE_CONTACT_DETAILS
        ]),
        async submit() {
            const reqest = {
                address: this.address,
                city: this.city,
                zip: this.zip,
                country: this.country
            }
            if (this.region) { reqest.region = this.region }
            try {
                await this.ACTION_UPDATE_CONTACT_DETAILS(reqest)
                this.$emit('goToNextStep')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style lang="scss">
    .wizard-address {
        width: 100%;
    }

    .wizard-header{
        padding: 25px;
        border-bottom: 1px solid #EAEAEA;
        h1 {
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 15px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .wizard-body{
        padding: 25px;
        display: flex;
        @media screen and (max-width: 576px){
            flex-direction: column;
        }
    }

    .wizard-body__info{
        width: 35%;
        @media screen and (max-width: 576px){
            width: 100%;
            margin-bottom: 40px;
        }
        h2 {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 15px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .wizard-address__form {
        width: 65%;
        @media screen and (max-width: 576px){
            width: 100%;
        }
    }

    .wizard-address__form-wrapper{
        display: flex;
        flex-direction: column;
        &--first-level {
            margin-bottom: 40px;
            @media screen and (max-width: 768px){
                margin-bottom: 0;
            }
        }
        &--second-level {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            .wizard-address__field{
                width: 48%;
                @media screen and (max-width: 768px){
                    width: 100%;
                }
            }
            @media screen and (max-width: 768px){
                flex-direction: column;
                margin-bottom: 0;
            }
        }
        &--third-level {
            display: flex;
            @media screen and (max-width: 1200px){
                justify-content: space-between;
            }
            @media screen and (max-width: 768px){
                flex-direction: column;
            }
            .wizard-address__field{
                width: 35%;
                @media screen and (max-width: 1200px){
                    width: 48%;
                }
                @media screen and (max-width: 768px){
                    width: 100%;
                }
            }
        }
    }

    .wizard-address__field {
        width: 100%;
        &--region{
            margin-left: 128px;
            @media screen and (max-width: 1200px){
                margin-left: 0;
            }
            @media screen and (max-width: 768px){
                margin-bottom: 0 !important;
            }
        }
        @media screen and (max-width: 768px){
            width: 99%;
            margin-bottom: 40px;
        }
    }

    .wizard-address__btn{
        display: flex;
        justify-content: center;
        padding: 25px;
        border-top: 1px solid #EAEAEA;
        .btn-base{
            width: 200px !important;
        }
    }

    .flag-icon-background {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    .flag-icon {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        position: relative;
        display: inline-block;
        width: 1.33333333em;
        line-height: 1em;
    }

    .flag-icon:before {
        content: "\00a0";
    }
</style>
